import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ProductsService } from "../services/products.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private productsService: ProductsService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.checkLogout();
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  checkLogout() {
    localStorage.removeItem("isLoggedIn");
    localStorage.setItem("cartItem", JSON.stringify([]));
    localStorage.removeItem("sizeFilter");
    localStorage.removeItem("fabricsFilter");
    localStorage.removeItem("compressionLevelFilter");
    localStorage.removeItem("categoryFilter");
    localStorage.removeItem("em ail");
    window.location.href = "wh/vimvigr/pages/login";
  }
}
