import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
//import { url_text, token_text, first_api, start_customer_login } from 'variables';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	constructor(private httpClient: HttpClient, translate: TranslateService, private router: Router,
		private activateRoute: ActivatedRoute) {
		translate.setDefaultLang('en');
		translate.addLangs(['en', 'fr']);
	}

	ngOnInit() {
	}

}
