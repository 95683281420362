import { Routes } from '@angular/router';

import { MainComponent } from './main/main.component';
import { DemoComponent } from './demo/demo.component';
import { ActivationProcessComponent } from './pages/activation-process/activation-process.component';

let dynamic_urlValue = localStorage.getItem('user_url');
const islogin = localStorage.getItem('isLoggedIn');

if (!dynamic_urlValue) {
  dynamic_urlValue = '';
}

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'wh/',
    pathMatch: 'full'
  },
  {
    path: 'demo',
    component: DemoComponent
  },
  {
    path: 'wh/:clientname',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      },
      {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
      }
    ]
  },
  {
    path: 'ws/:name/activation-process',
    component: ActivationProcessComponent
  },
  {
    path: '**',
    redirectTo: 'wh/',
    pathMatch: 'full'
  }
];

