import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../../shared/services/products.service';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  
  today: number = Date.now();
  
  constructor(public productsService: ProductsService,) { }

  ngOnInit() {
  }

}
