import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { InstagramService } from "../../shared/services/instagram.service";
import { Http } from "@angular/http";
import { DomSanitizer } from "@angular/platform-browser";
declare var $: any;
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
// var HelloSign: any;
import HelloSign from "hellosign-embedded";
import { Observable } from "rxjs";
import { CardFormComponent } from "./card-form/card-form.component";
import { country } from "../../country";

const client = new HelloSign({
  clientId: "b8d75163b073d901c28148650bdc334b",
});
declare const google: any;

@Component({
  selector: "app-activation-process",
  templateUrl: "./activation-process.component.html",
  styleUrls: ["./activation-process.component.scss"],
})
export class ActivationProcessComponent implements OnInit, AfterViewInit {
  @ViewChild("form", { static: false }) postForm: ElementRef;
  private a_field: any;
  private b_field: any;
  private c_field: any;
  private show_form: any;
  public demoEndpoint: any;
  private reqBody: any;
  countryList = country;
  paymentType = "";
  electronicSign: "";
  sign = "";
  CustomerForm: FormGroup;
  customerStatus = false;
  listDivision: Array<any> = [];
  warehouseList: Array<any> = [];
  arAccountsList: Array<any> = [];
  currencyList: Array<any> = [];
  shipViaList: Array<any> = [];
  defaultDivision: Array<any> = [];
  customerCatagory: Array<any> = [];
  customerBuyingGroup: Array<any> = [];
  customerType: Array<any> = [];
  customerInactiveRational: Array<any> = [];
  staffList: Array<any> = [];
  creditTermsList: Array<any> = [];
  customerSourceList: Array<any> = [];
  territoryRepList: Array<any> = [];
  salesPeopleList: Array<any> = [];
  customerTitleList: Array<any> = [];
  path: string;
  customerFormStatus: boolean = true;
  message: string;
  status: any;
  customerErrorStatus: boolean;
  safeResourceUrl: any;

  // authorize.net variables
  AuthcustomerData: any = {};
  AuthcustomerToken: any;
  customerToken: any;
  envelope = "";
  from: any;
  private paymentCustomerId: string;
  customerData: any;
  signType = "";
  submitButtonStatus = false;
  cardForm: FormGroup;
  paymentCardStatus = false;
  paymentErrorMessage = false;
  helloSignButton = false;
  helloSignButtonRequired = true;
  paymentMessage = "";

  @ViewChild("addresstext", { static: false }) billingAddresstext: ElementRef;
  @ViewChild("shippingaddresstext", { static: false })
  shippingingAddresstext: ElementRef;

  public card_number_mask: any = {
    mask: "0000 0000 0000 0000",
    lazy: false,
  };
  public tax_id_number: any = {
    mask: "00{-}0000000",
    lazy: false,
  };
  public phone_number: any = {
    mask: "000{-}000{-}0000",
    lazy: false,
  };
  today: number = new Date().getFullYear();
  paymentTypeError: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private service: InstagramService,
    public dialog: MatDialog,
    private http: Http,
    private sanitizer: DomSanitizer
  ) {
    this.demoEndpoint = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://accept.authorize.net/payment/payment"
    );
    /* this would be the url that you load with the iframe, that is also the value in the action field
     on the form to be issued in the post request */
    this.a_field = "value a";
    this.b_field = "value b";
    this.c_field = "value c";
    this.show_form = "PAYMENT_FORM";
    this.safeResourceUrl = this.demoEndpoint;
    this.reqBody = new FormData();
    this.reqBody.append("token", "6VYL9cb55");
    // this.reqBody.append('b_field', this.b_field);
    // this.reqBody.append('c_field', this.c_field);
    // this.reqBody.append('show_form', this.show_form);
    // HelloSign.init("3fc39383d4003144f1954f6d3c601ffe");

    this.cardForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      cardNumber: ["", Validators.required],
      expirationMonth: ["", Validators.required],
      expirationYear: ["", Validators.required],
      cardCode: ["", Validators.required],
      company: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      phoneNumber: "",
      faxNumber: "",
    });
  }

  ngOnInit() {
    $.getScript("assets/js/script.js");
    this.createForm();
    this.route.paramMap.subscribe((params) => {
      this.path = params.get("name");
      if (this.path === "/") {
        window.location.href = "https://repbro.awcs.in";
      } else {
        this.getPathDetails(this.path);
      }
    });
    if (this.signType == "docusign") {
      this.submitButtonStatus = false;
    } else {
      this.submitButtonStatus = true;
    }
  }
  ngAfterViewInit() {
    // HelloSign.open({
    // "skipDomainVerification": true,
    //     url:"https:\/\/app.hellosign.com\/editor\/embeddedSign?signature_id=de5ac29bcc212385765851ca0259d9ea&token=9559ba24901c5d93d9ad63a97f3b1fd8",
    //     allowCancel: true,
    //     messageListener: function(eventData) {
    //         alert("HelloSign event received");
    //     }
    // });s
  }

  submitForm($event): boolean {
    $event.stopPropagation();
    this.http.post(this.demoEndpoint, this.reqBody);
    return true;
  }

  onLoad(event) {}
  getCustomerProfileId(account_number, email, name) {
    this.service
      .AuthorizenetCreateCustomer(account_number, email, name)
      .subscribe((data) => {
        if (data.messages.resultCode == "Error") {
          this.getCustomerAuthorisationId();
        } else {
          this.AuthcustomerData = data;
          this.getCustomerProfilePage(this.AuthcustomerData.customerProfileId);
          // this.openPaymentDialogue(this.AuthcustomerData.customerProfileId);
          this.setCustomerAuthorisationId(
            this.AuthcustomerData.customerProfileId
          );
        }
      });
  }
  getCustomerProfilePage(profileId) {
    this.service.AuthorizenetGetCustomer(profileId).subscribe((data) => {
      this.AuthcustomerToken = data.token;
      setTimeout(() => {
        // this.postForm.nativeElement.submit();
        this.cardForm.patchValue({
          firstName: this.CustomerForm.get("customer.first_name").value,
          lastName: this.CustomerForm.get("customer.last_name").value,
          address: this.CustomerForm.get("billing_address.address_1").value,
          city: this.CustomerForm.get("billing_address.city").value,
          state: this.CustomerForm.get("billing_address.state").value,
          zip: this.CustomerForm.get("billing_address.postal_code").value,
          country: this.CustomerForm.get("billing_address.country").value,
          phoneNumber: this.CustomerForm.get("billing_address.phone").value,
        });
        $(".newsletterm").modal({
          backdrop: "static",
          keyboard: false,
        });
      });
    });
  }

  updateCardDetails() {
    if (this.cardForm.valid) {
      this.service
        .UpdateCardDetails(
          this.cardForm.value,
          this.AuthcustomerData.customerProfileId
        )
        .subscribe((data) => {
          if (data.messages.resultCode == "Error") {
            this.paymentMessage = data.messages.message[0].text;
            this.paymentCardStatus = false;
            this.paymentErrorMessage = true;
          } else if (data.messages.resultCode == "Ok") {
            this.paymentCardStatus = true;
            this.paymentErrorMessage = false;
            this.paymentMessage = "";
            $(".newsletterm").modal("hide");
          }
        });
    } else {
      this.markAsFormGroupTouched(this.cardForm);
    }
  }
  getCustomerAuthorisationId() {
    this.service
      .getCustomerAuthorisationId(this.customerData.id)
      .subscribe((data) => {
        this.AuthcustomerData.customerProfileId = data.authProfileId;
        this.getCustomerProfilePage(data.authProfileId);
        // this.openPaymentDialogue(data.authProfileId);
      });
  }
  openPaymentDialogue(profileId) {
    const dialogRef = this.dialog.open(CardFormComponent, {
      width: "500px",
      data: profileId,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  setCustomerAuthorisationId(authId) {
    this.service
      .setCustomerAuthorisationId(this.customerData.id, authId)
      .subscribe((data) => {
        // this.getCustomerProfilePage(data.authProfileId);
      });
  }
  getPathDetails(newPath: string) {
    this.service.getValidateFunction(newPath).subscribe(
      (data) => {
        if (data.error == "No details found") {
          window.location.href = "https://repbro.awcs.in";
        } else {
          this.signType = data.sign_type;
          this.route.queryParams.subscribe((params) => {
            this.customerToken = params.token;
            this.from = params.reg;
            this.tokenValidation(params.token);
          });
        }
      },
      (Error) => {
        window.location.href = "https://repbro.awcs.in";
      }
    );
  }

  private tokenValidation(token) {
    this.service.validateInvitationtoken(token).subscribe(
      (data) => {
        if (
          data.response.status == "Invitation Sent" ||
          data.response.status == "Created" ||
          data.response.status == "Inactive"
        ) {
          this.customerData = data.response;
          this.CustomerForm.patchValue({
            name: data.response.name,
            phone: data.response.phone,
            username: data.response.username,
          });
          if (
            data.response.shipping_address[0] != [] &&
            data.response.shipping_address[0] != null &&
            data.response.shipping_address[0] != undefined
          ) {
            this.CustomerForm.patchValue({
              shipping_address: data.response.shipping_address[0],
            });
          }
          if (
            data.response.billing_address[0] != [] &&
            data.response.billing_address[0] != null &&
            data.response.billing_address[0] != undefined
          ) {
            this.CustomerForm.patchValue({
              billing_address: data.response.billing_address[0],
            });
          }
          if (
            data.response.customer != {} &&
            data.response.customer != null &&
            data.response.customer != undefined
          ) {
            this.CustomerForm.patchValue({
              customer: data.response.customer,
            });
          }
          if (
            data.response.customer_tax_details != [] &&
            data.response.customer_tax_details != null &&
            data.response.customer_tax_details.length > 0 &&
            (data.response.customer_tax_details[0].duns != "" ||
              data.response.customer_tax_details[0].taxpayer_id_number != "" ||
              data.response.customer_tax_details[0].years_in_business != "")
          ) {
            this.paymentType = "later";
            this.CustomerForm.patchValue({
              customer_tax_details: data.response.customer_tax_details[0],
            });
          }
          this.getALlList();
          this.customerFormStatus = true;
          this.customerErrorStatus = false;
          this.customerStatus = false;
          this.getPlaceAutocomplete();
        } else if (data.response == "failed") {
          this.customerErrorStatus = true;
          this.customerFormStatus = false;
          this.customerStatus = false;
        } else {
          this.customerStatus = true;
          this.customerFormStatus = false;
          this.customerErrorStatus = false;
          this.message = data.response.name;
          this.status = data.response.status;
        }
      },
      (Error) => {}
    );
  }

  get customerTaxForm(): FormGroup {
    if (this.CustomerForm) {
      return <FormGroup>this.CustomerForm.controls["customer_tax_details"];
    }
  }
  get customerDetailForm(): FormGroup {
    if (this.CustomerForm) {
      return <FormGroup>this.CustomerForm.controls["customer"];
    }
  }
  handleChange(event) {
    if (this.paymentType == "now") {
      this.customerDetailForm.controls["terms_id"].setValue(1003);
      this.customerTaxForm.controls["taxpayer_id_number"].setValidators(null);
      this.customerTaxForm.controls[
        "taxpayer_id_number"
      ].updateValueAndValidity();
      this.customerTaxForm.controls["duns"].setValidators(null);
      this.customerTaxForm.controls["duns"].updateValueAndValidity();
      this.customerTaxForm.controls["years_in_business"].setValidators(null);
      this.customerTaxForm.controls[
        "years_in_business"
      ].updateValueAndValidity();
    } else {
      // this.customerDetailForm.controls['terms_id'].setValue(1004)
      // this.customerTaxForm.controls['taxpayer_id_number'].setValidators(Validators.required);
      // this.customerTaxForm.controls['taxpayer_id_number'].updateValueAndValidity()
      // this.customerTaxForm.controls['duns'].setValidators(Validators.required);
      // this.customerTaxForm.controls['duns'].updateValueAndValidity()
      // this.customerTaxForm.controls['years_in_business'].setValidators(Validators.required);
      // this.customerTaxForm.controls['years_in_business'].updateValueAndValidity()
    }
  }
  handleelectronicSignChange(event) {
    if (this.sign === "wet") {
      this.submitButtonStatus = false;
    } else if (this.sign === "electronic") {
      if (!this.helloSignButton) {
        this.submitButtonStatus = true;
      }
    }
  }

  updateProcess() {
    if (this.CustomerForm.valid) {
      // if (this.paymentType != '') {
      // this.paymentTypeError = false;
      if (this.signType == "docusign") {
        if (this.paymentType == "now") {
          // if (this.paymentCardStatus) {
          this.getCustomerProfileId(
            this.CustomerForm.controls["customer"].value.account_number,
            this.CustomerForm.controls["username"].value,
            this.CustomerForm.controls["name"].value
          );
          this.paymentErrorMessage = false;
          // } else {
          //   this.paymentErrorMessage = true;
          // }
        } else {
          if (this.customerTaxForm.valid) {
            this.openDialog();
          } else {
            this.markAsFormGroupTouched(this.customerTaxForm);
          }
        }
      } else if (this.signType === "hellosign") {
        if (this.paymentType === "now") {
          // if (this.paymentCardStatus) {
          this.paymentErrorMessage = false;
          this.updateBillingAddress();
          this.updateShippingAddressByForm();
          this.updateCustomer();
          if (this.sign === "electronic") {
            this.endHelloSign();
          } else {
            this.endManualProcess();
          }
          // } else {
          //   this.paymentErrorMessage = true;
          // }
        } else {
          if (this.customerTaxForm.valid) {
            this.updateBillingAddress();
            this.updateShippingAddressByForm();
            this.updateCustomer();
            if (this.sign === "electronic") {
              this.endHelloSign();
            } else {
              this.endManualProcess();
            }
          } else {
            this.markAsFormGroupTouched(this.customerTaxForm);
          }
        }
      }
      // } else {
      //   this.paymentTypeError = true;
      // }
    } else {
      this.markAsFormGroupTouched(this.customerTaxForm);
    }
  }

  openCardForm() {
    this.getCustomerProfileId(
      this.CustomerForm.controls["customer"].value.account_number,
      this.CustomerForm.controls["username"].value,
      this.CustomerForm.controls["name"].value
    );

    // const dialogRef = this.dialog.open(CardFormComponent, {
    //   width: 'auto',
    // });

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
  updateBillingAddress() {
    this.service
      .updateBillingAddress(
        this.CustomerForm.controls["billing_address"].value,
        this.customerData.billing_address[0].id,
        this.customerData.id
      )
      .subscribe((responseData) => {});
  }
  updateShippingAddressByForm() {
    this.service
      .updateShippingAddress(
        this.CustomerForm.controls["shipping_address"].value,
        this.customerData.shipping_address[0].id,
        this.customerData.id
      )
      .subscribe((responseData) => {});
  }
  endHelloSign() {
    this.service.endHellosignProcess(this.customerToken).subscribe((data) => {
      if (data.response == "success") {
        this.message = data.name;
        this.status = data.status;
        this.envelope = data.envelope_id;
        this.customerStatus = true;
        this.customerFormStatus = false;
        this.customerErrorStatus = false;
      }
    });
  }
  updateCustomer() {
    this.service
      .updateCustomer(this.CustomerForm.value, this.customerData.id)
      .subscribe((data: any) => {
        if (data.response == "failed") {
        } else {
        }
      });
  }

  createNewStaff() {}

  updateProcess1() {
    this.callStartHellosignProcess().subscribe((data) => {
      client.open(data.sign_url, {
        messageListener: function (eventData) {
          if (eventData.event == HelloSign.EVENT_SIGNED) {
            // do something for document getting signed
            // console.log('signed');
          } else if (eventData.event == HelloSign.EVENT_CANCELED) {
            // do something else for document closing before getting signed
            // console.log('before getting signed');
          } else if (eventData.event == HelloSign.EVENT_ERROR) {
            // do something for an error situation
            // console.log('before getting errror');
          } else if (eventData.event == HelloSign.EVENT_SENT) {
            // not used in this example
            // only used for embedded requesting
            // console.log('before embedded requesting signed');
          }
        },
      });
      client.on("sign", () => {
        this.submitButtonStatus = false;
        this.helloSignButton = true;
        this.helloSignButtonRequired = false;
      });
    });
  }

  callStartHellosignProcess(): Observable<any> {
    return this.service.callStartHellosignProcess(this.customerToken);
  }

  // call docusign()
  callDocusign() {
    this.service
      .callDocsign(this.CustomerForm.value, this.customerToken)
      .subscribe(
        (data) => {
          this.message = data.name;
          this.status = data.status;
          this.envelope = data.envelope_id;
          this.customerStatus = true;
          this.customerFormStatus = false;
          this.customerErrorStatus = false;
        },
        (error) => {
          alert("something went wrong please try again");
        }
      );
  }

  endManualProcess() {
    this.service
      .endManualProcess(this.CustomerForm.value, this.customerToken)
      .subscribe(
        (data) => {
          this.message = data.name;
          this.status = data.status;
          this.envelope = data.envelope_id;
          this.customerStatus = true;
          this.customerFormStatus = false;
          this.customerErrorStatus = false;
        },
        (error) => {
          alert("something went wrong please try again");
        }
      );
  }
  getALlList() {
    this.service.getAllListOptions().subscribe((data) => {
      this.listDivision = data[0].response === "failed" ? [] : data[0].response;
      this.warehouseList =
        data[1].response === "failed" ? [] : data[1].response;
      this.arAccountsList =
        data[2].response === "failed" ? [] : data[2].response;
      this.currencyList = data[3].response === "failed" ? [] : data[3].response;
      this.shipViaList = data[4].response === "failed" ? [] : data[4].response;
      this.defaultDivision =
        data[5].response === "failed" ? [] : data[5].response;
      this.customerCatagory =
        data[6].response === "failed" ? [] : data[6].response;
      this.customerBuyingGroup =
        data[7].response === "failed" ? [] : data[7].response;
      this.customerType = data[8].response === "failed" ? [] : data[8].response;
      this.customerInactiveRational =
        data[9].response === "failed" ? [] : data[9].response;
      this.staffList = data[10].response === "failed" ? [] : data[10].response;
      this.creditTermsList =
        data[11].response === "failed" ? [] : data[11].response;
      this.customerSourceList =
        data[12].response === "failed" ? [] : data[12].response;
      this.territoryRepList =
        data[13].response === "failed" ? [] : data[13].json().response;
      this.salesPeopleList =
        data[14].response === "failed" ? [] : data[14].json().response;
      this.customerTitleList =
        data[15].response === "failed" ? [] : data[15].json().response;
    });
  }

  createForm() {
    this.CustomerForm = this.fb.group({
      name: ["", [Validators.required]],
      username: [
        "",
        [Validators.compose([Validators.required, Validators.email])],
      ],
      phone: ["", [Validators.compose([Validators.required])]],
      customer: this.fb.group({
        fax: "",
        business_name: "",
        buyer_name: "",
        shipping_info: "",
        buying_group: "",
        account_number: "2154",
        customer_name: "",
        active: "2154",
        first_name: "",
        last_name: "",
        terms_id: "",
        credit_limit: "",
        status: "",
        category: "",
        division_id: "",
        warehouse_id: "",
        ar_acct: "",
        customer_credit_acct: "",
        website: "",
        notes: "",
        pct_discount: "",
        is_active: true,
        buyer_filter: "",
        edi_department: "",
        factor_account_number: "",
        anet_id: "2154",
        currency_id: "2154",
        price_group: "",
        xero_id: "2154",
        xero_synced: "",
        shopify_id: "2154",
        quickbooks_id: "2154",
        shipping_terms_id: "2154",
        ship_via: "",
        quickbooks_sync_status: "",
        quickbooks_sync_message: "",
        quickbooks_sync_datetime: "",
        custom_order_print_id: "",
        custom_invoice_print_id: "",
        custom_pick_ticket_print_id: "",
        joor_synced: "",
        priority: "",
        joor_customer_code: "",
        last_modified_time: "",
        salespeople: ["", [Validators.compose([Validators.required])]],
        inactive_date: "",
        type: "",
        inactive_rationale: "",
        account_manager: "",
        territory_rep: ["", [Validators.compose([Validators.required])]],
        opened_by: "",
        source: "",
        magicforce: "2154",
        reactivation_date: "",
        under_review: false,
        currency_name: "",
        position: "",
      }),
      shipping_address: this.fb.group({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        phone: "",
        email: "",
        is_main_location: "No",
        addresscheckbox: false,
      }),
      billing_address: this.fb.group({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        phone: "",
        email: "",
        is_main_location: "No",
      }),
      customer_tax_details: this.fb.group({
        taxpayer_id_number: "",
        duns: "",
        years_in_business: "",
      }),
    });
  }

  updateShippingAddress(event) {
    this.CustomerForm.controls.shipping_address.patchValue(
      this.CustomerForm.controls.billing_address.value
    );
    this.CustomerForm.get("shipping_address.email").setValue(
      this.CustomerForm.controls.username.value
    );
    this.CustomerForm.get("shipping_address.name").setValue(
      this.CustomerForm.controls.name.value
    );
    this.CustomerForm.get("shipping_address.phone").setValue(
      this.CustomerForm.controls.phone.value
    );
  }
  openDialog(): void {
    if (this.signType == "docusign") {
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: "450px",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.openYesNoDialog("automatic");
        } else if (result == false) {
          this.openYesNoDialog("manual");
        }
      });
    }
  }

  openYesNoDialog(type): void {
    let msgData: string = "";
    if (type == "automatic") {
      msgData = "You are about to start DocuSign Process, are you sure?";
    } else if (type == "manual") {
      msgData = "Have you received the manual signed copy?";
    }
    const dialogRef = this.dialog.open(YesOrNoDialog, {
      width: "400px",
      data: msgData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (type == "automatic") {
          this.callDocusign();
        } else if (type == "manual") {
          this.endManualProcess();
        }
      } else {
        if (type == "manual") {
          this.openReAttemptDialogue();
        }
      }
    });
  }

  openReAttemptDialogue() {
    const dialogRef = this.dialog.open(ReAttemptDocusign, {
      width: "600px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.callDocusign();
      } else if (result == false) {
        this.endManualProcess();
      }
    });
  }

  setTerrotoryRep(people) {
    let data = this.salesPeopleList.find((x) => x.name == people);
    this.service.getterritoryRepListWithId(data.id).subscribe((data: any) => {
      if (data.response != "failed") {
        this.territoryRepList = data.response;
      } else this.territoryRepList = [];
    });
  }
  markAsFormGroupTouched(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
      form.get(key).markAsTouched();
    });
  }

  public postalCodeChange(postal_code, formGroupName) {
    if (postal_code.length >= 5) {
      this.service
        .getLocationDetails(postal_code)
        .subscribe((responseData: any) => {
          const data = responseData.json();
          console.log(data);
          if (data.status === "OK") {
            let formGroup = <FormGroup>(
              this.CustomerForm.controls[formGroupName]
            );
            const addressComponent = data.results[0].address_components;
            this.setFormGroupValues(formGroup, addressComponent);
          }
        });
    }
  }
  private getPlaceAutocomplete() {
    const billingAutocomplete = new google.maps.places.Autocomplete(
      this.billingAddresstext.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ["geocode"], // 'establishment' / 'address' / 'geocode'
      }
    );
    const shippingAutocomplete = new google.maps.places.Autocomplete(
      this.shippingingAddresstext.nativeElement,
      {
        types: ["geocode"], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(billingAutocomplete, "place_changed", () => {
      const place = billingAutocomplete.getPlace();
      console.log(place);
      this.setFormGroupValues(
        <FormGroup>this.CustomerForm.controls["billing_address"],
        place.address_components
      );
    });
    google.maps.event.addListener(shippingAutocomplete, "place_changed", () => {
      const place = shippingAutocomplete.getPlace();
      this.setFormGroupValues(
        <FormGroup>this.CustomerForm.controls["shipping_address"],
        place.address_components
      );
    });
  }
  setFormGroupValues(formGroup: FormGroup, addressComponent) {
    addressComponent.forEach((components) => {
      if (components.types.indexOf("locality") > -1) {
        formGroup.controls["city"].setValue(components.long_name);
      }
      if (components.types.indexOf("country") > -1) {
        formGroup.controls["country"].setValue(components.long_name);
      }
      if (components.types.indexOf("administrative_area_level_1") > -1) {
        formGroup.controls["state"].setValue(components.short_name);
      }
      if (components.types.indexOf("postal_code") > -1) {
        formGroup.controls["postal_code"].setValue(components.short_name);
      }
    });
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  template:
    '<h3 mat-dialog-title>Signing and Acknowledgement of Terms!</h3><div mat-dialog-content style="text-align:center;display: block;"><p>Physical signatures of the Terms and Conditions must be emailed to donna@vimvigr.com within 48 hours.</p><p><b>NOTE:</b> Electronic Signature will send an email to the main contact to review the information.</p></div><div mat-dialog-actions style="text-align:center;display: block;border-top: 1px solid #efeaea;"><button mat-button [mat-dialog-close]="false" cdkFocusInitial>Physical Signature</button><button mat-button [mat-dialog-close]="true" cdkFocusInitial>Electronic Signature</button></div>',
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  template:
    '<h3 mat-dialog-title>Signing and Acknowledgement of Terms!</h3><div mat-dialog-content style="text-align:center;display: block;"><p>We cannot proceed until there is a physical signature. If that is not possible, please click Electronic Signature so the customer could complete their registration.</p></div><div mat-dialog-actions style="text-align:center;display: block;border-top: 1px solid #efeaea;"><button mat-button [mat-dialog-close]="cancel" cdkFocusInitial>Close</button><button mat-button [mat-dialog-close]="false" cdkFocusInitial>Physical Signature</button><button mat-button [mat-dialog-close]="true" cdkFocusInitial>Electronic Signature</button></div>',
})
export class ReAttemptDocusign {
  cancel: string = "cancel";
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "yes-no",
  template:
    '<div mat-dialog-content><p>{{data}}</p></div><div mat-dialog-actions style="text-align:center;display: block;border-top: 1px solid #efeaea;"><button mat-button [mat-dialog-close]="false" cdkFocusInitial>No</button><button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button></div>',
})
export class YesOrNoDialog {
  constructor(
    public dialogRef: MatDialogRef<YesOrNoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
