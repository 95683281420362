export const url_text = localStorage.getItem("user_url");
export const api_path = localStorage.getItem("api_path");
export const a_token = localStorage.getItem("auth_token");
export const api_token = localStorage.getItem("api_token");
export const url_text_check = 'cart';
export const currency_symbol = '$';

export const token_text = 'KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8';
//export const a_token = '5a92b72ca3cdb05bb234cff9252e2f5727cc2c4f';
export const first_api = 'https://api.repbro.com/checkAccount';
export const start_customer_login = 'https://api.repbro.com/' + url_text + '/startCustomerLogin';
export const second_customer_login = 'https://api.repbro.com/' + url_text + '/customerLogin';
export const productlisting = 'https://api.repbro.com/' + url_text + '/cartproducts';
export const product_sing = 'https://api.repbro.com/' + url_text + '/cartproductDetails';
export const for_pass = 'https://api.repbro.com/' + url_text + '/customerForgetPassword';
export const changepass_otp = 'https://api.repbro.com/' + url_text + '/customerChangePasswordWithOtp';
export const save_order = 'https://api.repbro.com/' + url_text + '/saveorder';
export const createdraftorder = 'https://api.repbro.com/' + url_text + '/createdraftorder';
export const updatedraftorder = 'https://api.repbro.com/' + url_text + '/updatedraftorder';
export const listdraftorder = 'https://api.repbro.com/' + url_text + '/listdraftorder';
export const orderhistory = 'https://api.repbro.com/' + url_text + '/orderhistory';
export const invoicehistory = 'https://api.repbro.com/' + url_text + '/invoicehistory';
export const deletedraftorder = 'https://api.repbro.com/' + url_text + '/deletedraftorder';
export const getCustomer = 'https://api.repbro.com/' + url_text + '/getCustomer';
export const customerChangePassword = 'https://api.repbro.com/' + url_text + '/customerChangePassword';
export const logobycustomerid = 'https://api.repbro.com/' + url_text + '/epicstitch/logobycustomerid';
export const position = 'https://api.repbro.com/' + url_text + '/epicstitch/position';
export const colorchoice = 'https://api.repbro.com/' + url_text + '/epicstitch/colorchoice';
export const addShippingAddress = 'https://api.repbro.com/' + url_text + '/b2b/addShippingAddress';
export const getShippingAddress = 'https://api.repbro.com/' + url_text + '/b2b/getShippingAddress';
