import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { url_text, token_text, first_api, second_customer_login, start_customer_login, for_pass, changepass_otp, listdraftorder } from '../../variables';
import * as $ from 'jquery';// import Jquery here 
import { ProductsService } from './products.service';
/*****get variables from variables.ts**********/
var base_url = url_text
var token = token_text
var startCLogin = start_customer_login
var cLogin = second_customer_login
var cLogin = second_customer_login
var check_account = first_api
var customerFPassword = for_pass
var cPasswordOTP = changepass_otp
var listdraftorderUrl = listdraftorder

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	constructor(private httpClient: HttpClient, private activeRoute: Router, private router: Router,
		public productsService: ProductsService,) { }

	/******check email******/

	checkEmailFirst(email) {
		$(".infinite-scrolling").show();
		let data = {
			"request": {
				"name": this.productsService.companyData.name
			},
			"header": {
				"token": token
			}
		}
		this.httpClient.post(check_account, data).subscribe(data => {
			if (data["name"] == this.productsService.companyData.name) {
				this.checkifemail(email, data["api_token"]);

			} else {
				$(".invalid_request").show();
				//$(".infinite-scrolling").hide();
			}
		});
	}

	/****main fun to call on component request to check email*****/
	startCustomerLogin(email, password, account) {

		$('.infinite-scrolling').show();
		if (base_url == null) {
			base_url = localStorage.getItem('user_url');
		}
		let data = {
			"request": {
				"name": base_url
			},
			"header": {
				"token": token
			}
		}
		this.httpClient.post(check_account, data).subscribe(data => {
			if (data["name"] == base_url) {
				this.startFirstLogin(email, password, data["api_token"], account);
			} else {
				$(".invalid_request").show();
			}
		});

	}

	/******check email exist*******/

	checkifemail(email, api) {

		let data = {
			"request": { "username": email },
			"header": { "token": token, "apiToken": api }
		}
		return this.httpClient.post(this.productsService.companyData.api_path + 'startCustomerLogin', data).subscribe(data => {
			//$(".infinite-scrolling").show();
			if (data["status"] == "success") {
				$(".second_password").show();
				$(".conemail").remove();
				$(".loginbtn").show();
				$(".error_msg .email_invalid").hide();
				$(".infinite-scrolling").hide();
				if (data["multiple"] === true) {
					$(".third_accountid").show();
				}
			} else {
				$(".error_msg .email_invalid").text(data["error"]);
				$(".error_msg .email_invalid").show();
				//localStorage.removeItem("user_url");
				//setTimeout(()=>)
				/*setTimeout(()=>{    //<<<---    using ()=> syntax
					this.activeRoute.navigate(['/' ]);
				}, 500);*/
				$(".infinite-scrolling").hide();

			}
		}
			, error => { console.log("Error", error); });
	}

	/*****first login to check email******/

	startFirstLogin(email, password, api_token, account) {
		let paramData = {
			"request": { "username": email },
			"header": { "token": token, "apiToken": api_token }
		}
		this.httpClient.post(this.productsService.companyData.api_path + 'startCustomerLogin', paramData).subscribe((data) => {
			if (data["status"] == "success") {
				this.customerLogin1(email, password, api_token, account)
				localStorage.removeItem('fabricsFilter');
				localStorage.removeItem('categoryFilter');
				localStorage.removeItem('compressionLevelFilter');
				localStorage.removeItem('sizeFilter');
				localStorage.removeItem('cartItem');
				localStorage.removeItem('draft_id');
				$(".second_password").show();
				$('.infinite-scrolling').hide();
				if (data["multiple"] === true) {
					$(".third_accountid").show();
				}
			} else {
				$('.infinite-scrolling').hide();
				$(".error_msg .password_failed").hide();
				$(".error_msg .email_invalid").show();
			}
		}
			, error => { console.log("Error", error); });
	}

	/******second request to check password email and pass*******/
	customerLogin1(email, password, api_token, account) {
		let data = {
			"request": {
				"username": email,
				"password": password,
				"accountid": account
			},
			"header": {
				"token": token,
				"apiToken": api_token
			}
		}

		/*****Login check if success or not*******/

		return this.httpClient.post(this.productsService.companyData.api_path + 'customerLogin', data).subscribe(data => {

			if (data["login"] == "success") {
				$(".login_success").text(data['message']);
				$(".login_success").show();
				$(".error_msg .email_invalid").hide();
				$(".error_msg .password_failed").hide();
				localStorage.setItem('isLoggedIn', "true");
				localStorage.setItem('auth_token', data["token"]);
				localStorage.setItem('email', data["username"]);
				localStorage.setItem('email', data["username"]);
				localStorage.setItem('userId', data["userid"]);
				localStorage.setItem('price_group', data["price_group"]);
				localStorage.setItem('free_embroidery', data["free_embroidery"]);
				this.getCustomerDetails(api_token, data["token"], data["userid"]).subscribe((userData: any) => {
					if (userData && userData.customer && userData.customer.customer_id) {
						localStorage.setItem('customer_id', userData.customer.customer_id);
					}
					this.getDraftList(api_token, data["token"]);
				}, Error => {
					window.location.href = 'wh/' + base_url + "/collection/all";
				})
				//this.activeRoute.navigate([base_url+'/collection/all' ]);
			}
			if (data["login"] == "failed") {
				$(".error_msg .email_invalid").hide();
				$(".error_msg .password_failed").text(data["error"]);
				$(".error_msg .password_failed").show();
			}
		}
			, error => { console.log("Error", error); });
	}

	getCustomerDetails(api_token, authtoken, userId) {
		let request = {
			'customer_id': userId,
		};
		let header = {
			'token': 'KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8',
			'apiToken': api_token,
			'authToken': authtoken
		};
		let data = {
			request: request,
			header: header
		};
		return this.httpClient.post(this.productsService.companyData.api_path + 'getCustomer', data);
	}

	forgetPassword(email) {

		let data = {
			"request": {
				"name": base_url
			},
			"header": {
				"token": token
			}
		}

		$(".infinite-scrolling").show();
		this.httpClient.post(check_account, data).subscribe(data => {
			if (data["name"] == base_url) {
				this.forgotcheckemail(email, data["api_token"]);

			} else {
				$(".invalid_request").show();
				$(".infinite-scrolling").hide();
			}
		});

	}

	getDraftList(api_token, authtoken) {
		let request = {
		};
		let header = {
			'token': 'KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8',
			'apiToken': api_token,
			'authToken': authtoken
		};
		let data = {
			request: request,
			header: header
		}
			;
		const url = listdraftorderUrl;
		this.httpClient.post(this.productsService.companyData.api_path + 'listdraftorder', data).subscribe((response: any) => {
			if (response && response.response && response.response.length > 0) {
				localStorage.setItem('draft_id', response.response[0].draft_id);
				if (response.response[0] && response.response[0].json) {
					const products = JSON.parse(response.response[0].json);
					localStorage.setItem('cartItem', JSON.stringify(products.products));
				}
				window.location.href = 'wh/' + base_url + "/collection/all";
			} else {
				window.location.href = 'wh/' + base_url + "/collection/all";
			}
		}, Error => {
			window.location.href = 'wh/' + base_url + "/collection/all";
		});
	}

	forgotcheckemail(email, api) {

		let data = {
			"request": {
				"username": email
			},
			"header": {
				"token": token,
				"apiToken": api
			}
		}

		return this.httpClient.post(customerFPassword, data).subscribe(data => {
			$(".infinite-scrolling").hide();
			if (data["status"] == "success") {
				//window.location.href="/"+this.base_url+"/pages/changepassword?email="+email;
				$(".email_forgot").hide();
				$(".pass_succ_sent").show();
				$(".otp_code").show();
				if (data["multiple"] === true) {
					$(".third_accountid").show();
				}
			} else {
				$(".otp_fail").text(data["error"]);
				$(".otp_fail").show();
			}
		}
			, error => { console.log("Error", error); });
	}

	/******************get otp code********************/

	enterOTP(otp_code, email, new_pass, account_id) {
		let data = {
			"request": {
				"name": base_url
			},
			"header": {
				"token": token
			}
		}

		$(".infinite-scrolling").show();
		$(".pass_succ span").hide();
		$(".pass_succ").hide();
		$(".pass_succ_sent").hide();
		this.httpClient.post(check_account, data).subscribe(data => {
			if (data["name"] == base_url) {
				this.enterOTPCode(otp_code, email, new_pass, data["api_token"], account_id);

			} else {
				$(".infinite-scrolling").hide();
				$(".invalid_request").show();
			}
		});
	}

	/****chk otp fun*****/

	enterOTPCode(otp_code, email, pass, api_token, account_id) {
		var x = otp_code;
		var y = +x; // y: number
		let data = {
			"request": {
				"username": email,
				"otp": y,
				"password": pass,
				"accountid": account_id
			},
			"header": {
				"token": token,
				"apiToken": api_token
			}
		}

		return this.httpClient.post(cPasswordOTP, data).subscribe(data => {
			$(".infinite-scrolling").hide();
			if (data["status"] == "success") {
				$(".pass_succ span").text(data["message"]);
				$(".pass_succ").show();
				$(".pass_succ span").show();
				$(".otp_fail").hide();
				setTimeout(() => {
					this.router.navigateByUrl('wh/' + base_url + '/pages/login');
				}, 5000);
			} else {
				$(".otp_fail").show();
				$(".pass_succ").hide();
				$(".otp_fail span").text(data["error"]);
			}
		}
			, error => { console.log("Error", error); });
	}


	/*****************not used need to remove**************************/

	/***************code for change password*****************/

	getParams(url) {
		var params = {};
		var parser = document.createElement('a');
		parser.href = url;
		var query = parser.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			params[pair[0]] = decodeURIComponent(pair[1]);
		}

		return params;
	};


	checkUrl() {
		let data = {
			"request": {
				"name": base_url
			},
			"header": {
				"token": token
			}
		}
		this.httpClient.post(check_account, data).subscribe(data => {
			if (data["name"] == base_url) {
			} else {
				//$(".invalid_request").show();
			}
		});
	}


	/*changePasswordOTP(otp_code,old_pass){
		/****custom request***
		
		let data= {"request":{
			"name": base_url
		},
		  "header":{
			"token":token
		  }	
		}
		this.httpClient.post(check_account,data).subscribe(data  => {
			//this.data = data.name;
			if(data["name"]==base_url){
				//const firstParam: string = this.activeRoute.get('email');
				var getemail = this.getParams(window.location.href);
				this.passReset(otp_code,old_pass,data["api_token"]);
				//this.startFirstLogin(email,password,data.api_token);
			} else {
				$(".invalid_request").show();
			}
		});
	}
	
	passReset(otp_code,old_pass,api_token){
		let data= {"request":{
			  "username":"aarvinms@gmail.com",
			  "otp":248300,
			  "password":"12345678"
			},
			"header":{
				"token":token,
				"apiToken":api_token
			}
		}
		this.httpClient.post(cPasswordOTP,data).subscribe(data  => {
			
		});
	}*/


}
