// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  megaMenuType?: string; // small, medium, large
  image?: string;
  children?: Menu[];
}

export const MENUITEMS: Menu[] = [
  // {
  //   title: 'home', type: 'sub'
  // },
  {
    title: 'Shop', type: 'subb', megaMenu: true, megaMenuType: 'medium'
  },
  {
    title: 'Support', type: 'support', megaMenu: true, megaMenuType: 'medium'
  },
  {
    title: 'About Us', type: 'about', megaMenu: true, megaMenuType: 'medium'
  },
]