import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { Http, HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from 'ngx-toastr';
import { rootRouterConfig } from './app.routes';
// import ngx-translate and the http loader
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// components
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { DemoComponent } from './demo/demo.component';
import { ReplacePipe } from './replace.pipe';
import { ActivationProcessComponent, DialogOverviewExampleDialog, YesOrNoDialog, ReAttemptDocusign } from './pages/activation-process/activation-process.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { IMaskModule } from 'angular-imask';
import { CardFormComponent } from './pages/activation-process/card-form/card-form.component';
import { ProductsService } from './shared/services/products.service';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DemoComponent,
    ActivationProcessComponent, CardFormComponent,
    ReplacePipe, DialogOverviewExampleDialog, YesOrNoDialog, ReAttemptDocusign, CardFormComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    IMaskModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: false,
      enableHtml: true,
    }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
  ],
  providers: [ProductsService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },],
  entryComponents: [CardFormComponent, DialogOverviewExampleDialog, YesOrNoDialog, ReAttemptDocusign, CardFormComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
