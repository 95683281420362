import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Observable, forkJoin } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class InstagramService {
  private productList = [];

  private _listingComplete = false;

  // Initialize
  constructor(private http: Http, private httpClient: HttpClient) {}

  // Instagram Array
  public getInstagramData(token) {
    return this.http.get(
      "https://api.instagram.com/v1/users/self/media/recent/?access_token=" +
        token
    );
    // return this.http.get('https://www.instagram.com/accounts/login/?next=/publicapi/v1/users/self/media/recent?max_id=15');
  }

  public get ProductList() {
    return this.productList;
  }
  public set ProductList(value) {
    this.productList = value;
  }

  public get listingComplete() {
    return this._listingComplete;
  }
  public set listingComplete(value) {
    this._listingComplete = value;
  }

  public validateInvitationtoken(token): Observable<any> {
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };

    let request = {
      token: token,
    };
    let data = {
      request: request,
      header: header,
    };
    return this.http
      .post("https://api.repbro.com/vimvigr/validateInvitationtoken", data)
      .map((res) => res.json());
  }

  public getAuthorizationNet(): Observable<any> {
    // let data = {
    //   "createCustomerProfileRequest": {
    //     "merchantAuthentication": {
    //       "name": "9P42DxQe3p",
    //       "transactionKey": "3wE98rD7Ec6n567k"
    //     },
    //     "profile": {
    //       "merchantCustomerId": "Merchant_Customer_ID",
    //       "description": "Profile description here",
    //       "email": "customer-profile-email@here.com",
    //       "paymentProfiles": {
    //         "customerType": "individual",
    //         "payment": {
    //           "creditCard": {
    //             "cardNumber": "4111111111111111",
    //             "expirationDate": "2020-12"
    //           }
    //         }
    //       }
    //     },
    //     "validationMode": "testMode"
    //   }
    // }
    let data = {
      paymentProfileId: "9P42DxQe3p",
      token: "3wE98rD7Ec6n567k",
    };
    return this.http
      .post("https://api.authorize.net/customer/manage", JSON.stringify(data))
      .map((res) => res.json());
  }
  public saveCustomerDetails(token): Observable<any> {
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    let data = {
      request: null,
      header: header,
    };
    return this.http
      .post(
        "https://api.repbro.com/vimvigr/validateInvitationtoken/" + token,
        data
      )
      .map((res) => res.json());
  }

  getValidateFunction(name): Observable<any> {
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
    };
    let request = {
      name: name,
    };
    let data = {
      request: request,
      header: header,
    };
    return this.http
      .post("https://api.repbro.com/checkAccount", data)
      .map((res) => res.json());
  }
  callDocsign(request, token): Observable<any> {
    // console.log(request);
    // console.log(token);

    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    request.token = token;
    let data = {
      request: request,
      header: header,
    };
    return this.http
      .post("https://api.repbro.com/vimvigr/startDocusignProcess", data)
      .map((res) => res.json());
  }
  callStartHellosignProcess(token): Observable<any> {
    // console.log(token);

    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    let request = {
      token: token,
    };
    let data = {
      request: request,
      header: header,
    };
    return this.http
      .post("https://api.repbro.com/vimvigr/startHellosignProcess", data)
      .map((res) => res.json());
  }
  endHellosignProcess(token): Observable<any> {
    // console.log(token);

    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    let request = {
      token: token,
    };
    let data = {
      request: request,
      header: header,
    };
    return this.http
      .post("https://api.repbro.com/vimvigr/endHellosignProcess", data)
      .map((res) => res.json());
  }
  endManualProcess(request, token): Observable<any> {
    // console.log(request);
    // console.log(token);

    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    request.token = token;
    let data = {
      request: request,
      header: header,
    };
    return this.http
      .post("https://api.repbro.com/vimvigr/endManualProcess", data)
      .map((res) => res.json());
  }
  getCustomerAuthorisationId(id): Observable<any> {
    let request = {
      customer_id: id,
    };
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    let data = {
      request: request,
      header: header,
    };
    return this.http
      .post("https://api.repbro.com/vimvigr/getAuthProfileId", data)
      .map((res) => res.json());
  }
  setCustomerAuthorisationId(id, authid): Observable<any> {
    let request = {
      customer_id: id,
      authProfileId: authid,
    };
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    let data = {
      request: request,
      header: header,
    };
    return this.http
      .post("https://api.repbro.com/vimvigr/setAuthProfileId", data)
      .map((res) => res.json());
  }

  AuthorizenetCreateCustomer(name, email, busines_name): Observable<any> {
    let data = {
      createCustomerProfileRequest: {
        merchantAuthentication: {
          name: "9P42DxQe3p",
          transactionKey: "3wE98rD7Ec6n567k",
        },
        profile: {
          merchantCustomerId: name,
          description: "RepBro - " + busines_name,
          email: email,
        },
      },
    };
    return this.http
      .post("https://api.authorize.net/xml/v1/request.api", data)
      .map((res) => res.json());
  }
  AuthorizenetGetCustomer(token): Observable<any> {
    let data = {
      getHostedProfilePageRequest: {
        merchantAuthentication: {
          name: "9P42DxQe3p",
          transactionKey: "3wE98rD7Ec6n567k",
        },
        customerProfileId: token,
        hostedProfileSettings: {
          setting: [
            {
              settingName: "hostedProfileReturnUrl",
              settingValue:
                "https://api.repbro.com/hook/vimvigr/authorizeCustomerPaymentProfileId/?",
            },
            {
              settingName: "hostedProfileReturnUrlText",
              settingValue: "Continue to confirmation page.",
            },
            {
              settingName: "hostedProfilePageBorderVisible",
              settingValue: "true",
            },
          ],
        },
      },
    };
    return this.http
      .post("https://api.authorize.net/xml/v1/request.api", data)
      .map((res) => res.json());
  }

  UpdateCardDetails(formData, profileId) {
    let data = {
      createCustomerPaymentProfileRequest: {
        merchantAuthentication: {
          name: "9P42DxQe3p",
          transactionKey: "3wE98rD7Ec6n567k",
        },
        customerProfileId: profileId,
        paymentProfile: {
          billTo: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            company: "",
            address: formData.address,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country,
            phoneNumber: formData.phoneNumber,
            faxNumber: "",
          },
          payment: {
            creditCard: {
              cardNumber: formData.cardNumber,
              expirationDate:
                (formData.expirationMonth > 9
                  ? formData.expirationMonth
                  : "0" + formData.expirationMonth) +
                "-" +
                (formData.expirationYear % 100),
              cardCode: formData.cardCode,
            },
          },
          defaultPaymentProfile: false,
        },
        validationMode: "liveMode",
      },
    };
    return this.http
      .post("https://api.authorize.net/xml/v1/request.api", data)
      .map((res) => res.json());
  }

  updateCustomer(request, id) {
    request.customer_id = id;
    delete request.billing_address;
    delete request.shipping_address;
    // request.system_status ? request.system_status = 1 : request.system_status = 0;
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    let data = {
      request: request,
      header: header,
    };

    const url = "https://api.repbro.com/vimvigr/updateCustomer";
    return this.http.post(url, data);
  }
  updateBillingAddress(request, id, customer_id) {
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    request.id = id;
    request.customer_id = customer_id;
    let data = {
      request: request,
      header: header,
    };

    const url = "https://api.repbro.com/vimvigr/updateBillingAddress";
    return this.http.post(url, data);
  }
  updateShippingAddress(request, id, customer_id) {
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    request.id = id;
    request.customer_id = customer_id;
    delete request.addresscheckbox;
    let data = {
      request: request,
      header: header,
    };

    const url = "https://api.repbro.com/vimvigr/updateShippingAddress";
    return this.http.post(url, data);
  }

  getterritoryRepListWithId(id) {
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    let request = {
      repid: id,
    };
    let data = {
      request: request,
      header: header,
    };

    const url = "https://api.repbro.com/vimvigr/territoryRepList";
    return this.http.post(url, data).map((res) => res.json());
  }

  getAllListOptions(): Observable<any> {
    let header = {
      token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8",
      apiToken: "safs85s7fs54df5s4d5f4s5df4s121x2cv1s4df",
      authToken: "ae878e856323a449d815b272c07f2cda8b5452da",
    };
    let request = {
      default: [13, 14, 15],
    };
    let data = {
      request: null,
      header: header,
    };
    let defaulDiv = {
      request: request,
      header: header,
    };
    const requestUrl1 = "https://api.repbro.com/vimvigr/" + "listDivision";
    const requestUrl2 = "https://api.repbro.com/vimvigr/" + "warehouseList";
    const requestUrl3 = "https://api.repbro.com/vimvigr/" + "arAccountsList";
    const requestUrl4 = "https://api.repbro.com/vimvigr/" + "currencyList";
    const requestUrl5 = "https://api.repbro.com/vimvigr/" + "shipViaList";
    const requestUrl6 = "https://api.repbro.com/vimvigr/" + "defaultDivision";
    const requestUrl7 = "https://api.repbro.com/vimvigr/" + "customerCatagory";
    const requestUrl8 =
      "https://api.repbro.com/vimvigr/" + "customerBuyingGroup";
    const requestUrl9 = "https://api.repbro.com/vimvigr/" + "customerType";
    const requestUrl10 =
      "https://api.repbro.com/vimvigr/" + "customerInactiveRational";
    const requestUrl11 = "https://api.repbro.com/vimvigr/" + "staffList";
    const requestUrl12 = "https://api.repbro.com/vimvigr/" + "creditTermsList";
    const requestUrl13 =
      "https://api.repbro.com/vimvigr/" + "customerSourceList";
    const requestUrl14 = "https://api.repbro.com/vimvigr/" + "territoryRepList";
    const requestUrl15 = "https://api.repbro.com/vimvigr/" + "salesPeopleList";
    const requestUrl16 =
      "https://api.repbro.com/vimvigr/" + "customerTitleList";
    const response1 = this.httpClient.post(requestUrl1, data);
    const response2 = this.httpClient.post(requestUrl2, data);
    const response3 = this.httpClient.post(requestUrl3, data);
    const response4 = this.httpClient.post(requestUrl4, data);
    const response5 = this.httpClient.post(requestUrl5, data);
    const response6 = this.httpClient.post(requestUrl6, defaulDiv);
    const response7 = this.httpClient.post(requestUrl7, data);
    const response8 = this.httpClient.post(requestUrl8, data);
    const response9 = this.httpClient.post(requestUrl9, data);
    const response10 = this.httpClient.post(requestUrl10, data);
    const response11 = this.httpClient.post(requestUrl11, data);
    const response12 = this.httpClient.post(requestUrl12, data);
    const response13 = this.httpClient.post(requestUrl13, data);
    const response14 = this.http.post(requestUrl14, data);
    const response15 = this.http.post(requestUrl15, data);
    const response16 = this.http.post(requestUrl16, data);
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([
      response1,
      response2,
      response3,
      response4,
      response5,
      response6,
      response7,
      response8,
      response9,
      response10,
      response11,
      response12,
      response13,
      response14,
      response15,
      response16,
    ]);
  }

  getLocationDetails(zipcode) {
    const url =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      zipcode +
      "&key=AIzaSyAIwaoMqVJN7C5nkb8htq4_Fy6oxBoSjVY";
    return this.http.get(url);
  }
}
