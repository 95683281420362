import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InstagramService } from 'src/app/shared/services/instagram.service';

@Component({
    selector: 'card-form',
    templateUrl: './card-form.component.html',
    styleUrls: ['./card-form.component.scss']
})
export class CardFormComponent implements OnInit {
    //Mask the Credit Card Number Input
    public card_number_mask: any = {
        mask: '0000 0000 0000 0000',
        lazy: false
    };

    //Mask the Expiration Date
    public expirationdate_mask: any = {
        mask: 'MM{/}YY',
        lazy: false

    }
    //Mask the security code       
    public securitycode_mask: any = {
        mask: '0000',
        lazy: false
    }

    today: number = (new Date()).getFullYear();

    cardForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private service: InstagramService,
        public dialogRef: MatDialogRef<CardFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.cardForm = this.fb.group({
            firstName: '',
            lastName: '',
            cardNumber: '',
            expirationMonth: '',
            expirationYear: '',
            cardCode: '',
            company: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            phoneNumber: '',
            faxNumber: '',
        })
    }

    ngOnInit() {
        // console.log(this.data);
    }
    updateProcess() {
        this.service.UpdateCardDetails(this.cardForm.value, this.data).subscribe(data => {
            // console.log(data);
        })
    }

}
