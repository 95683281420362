import { Component, OnInit } from '@angular/core';
import { Product } from '../../../../classes/product';
import { WishlistService } from '../../../../services/wishlist.service';
import { ProductsService } from '../../../../../shared/services/products.service';
import { Observable, of } from 'rxjs';
import { url_text, token_text, first_api, start_customer_login } from '../../../../../variables';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';

var url_code = url_text
var token = token_text
var check_account = first_api

var urlcode = url_text

@Component({
	selector: 'app-topbar',
	templateUrl: './topbar-one.component.html',
	styleUrls: ['./topbar-one.component.scss']
})
export class TopbarOneComponent implements OnInit {

	isLoggedIn = false;

	public user_url = url_text;
	logoimg: any;
	address_footer: any;
	phonenumber_footer: any;
	email_footer: any;
	fax_footer: any;
	userName = '';

	constructor(public productsService: ProductsService, private router: Router,
		private httpClient: HttpClient) { }

	ngOnInit() {
		const loginStatus = localStorage.getItem('isLoggedIn');
		if (loginStatus) {
			this.isLoggedIn = JSON.parse(loginStatus);
		}
		setTimeout(() => {
			this.userName = localStorage.getItem('userName');
		}, 1000);
	}
	get companyData() {
		return this.productsService.companyData;
	}

	checkLogout() {
		const emptyaar = [];
		localStorage.removeItem("isLoggedIn");
		localStorage.setItem("cartItem", JSON.stringify(emptyaar));
		localStorage.removeItem("sizeFilter");
		localStorage.removeItem("fabricsFilter");
		localStorage.removeItem("compressionLevelFilter");
		localStorage.removeItem("categoryFilter");
		localStorage.removeItem("email");
		window.location.href = 'wh/' + this.companyData.name + '/pages/login';
	}

	logoData(logo, address, phonenumber, email, fax) {
		this.logoimg = logo;
		this.address_footer = address;
		this.phonenumber_footer = phonenumber;
		this.email_footer = email;
		this.fax_footer = fax;
	}

	public forLogo() {
		//   console.log("forlogo");
		//   console.log("chk api fir");
		let data = {
			"request": {
				"name": url_code
			},
			"header": {
				"token": token
			}
		}
		this.httpClient.post(check_account, data).subscribe(data => {
			//this.data = data.name;

			// console.log(data["api_token"])
			if (data["name"] == url_code) {
				this.logoData(data['logo'], data['address'], data['phonenumber'], data['email'], data['fax'])
			} else {
				$(".invalid_request").show();
			}
		});
	}

}
