import { Component, OnInit } from '@angular/core';
import { MENUITEMS, Menu } from './navbar-items';
import { Router, ActivatedRoute } from "@angular/router";
import { ProductsService } from '../../../../shared/services/products.service';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public menuItems: Menu[];

  constructor(private productsService: ProductsService) { }

  ngOnInit() {
    this.menuItems = MENUITEMS.filter(menuItem => menuItem);
  }

  get companyData() {
    return this.productsService.companyData;
  }
}
