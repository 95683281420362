import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ProductsService } from "../shared/services/products.service";
import { CartService } from "../shared/services/cart.service";
import { WishlistService } from "../shared/services/wishlist.service";
import { HttpClient } from "@angular/common/http";
import { url_text } from "./../variables";
declare var $: any;

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
  providers: [CartService, WishlistService],
})
export class MainComponent implements OnInit {
  public url: any;

  constructor(
    private router: Router,
    private productsService: ProductsService,
    private activateRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    $.getScript("assets/js/script.js");
    this.activateRoute.params.subscribe((params) => {
      if (params["clientname"]) {
        this.checkValidity(params.clientname);
      } else {
        const api_name_url = localStorage.getItem("user_url");
        if (api_name_url) {
          this.router.navigateByUrl("wh/" + api_name_url);
        } else {
          this.router.navigateByUrl("wh/vimvigr/pages/login");
        }
      }
    });
  }

  checkValidity(paramData) {
    const data = {
      request: { name: paramData },
      header: { token: "KEY:GYHYH-SDFTG-67RTY-XCFGH-SDFT8" },
    };
    this.httpClient
      .post("https://api.repbro.com/checkAccount", data)
      .subscribe((responseData: any) => {
        if (responseData["error"] === "No details found") {
          const api_name_url = localStorage.getItem("user_url");
          if (api_name_url) {
            this.checkValidity(api_name_url);
          } else {
            this.router.navigateByUrl("wh/vimvigr/pages/login");
          }
        } else {
          const user_url = localStorage.getItem("user_url");
          console.log(user_url);
          const api_name_url = responseData["name"];
          const api_token = responseData["api_token"];
          const api_path = responseData["api_path"];
          localStorage.setItem("user_url", api_name_url);
          localStorage.setItem("api_token", api_token);
          localStorage.setItem("api_path", api_path);
          localStorage.setItem("userName", responseData["company_name"]);
          localStorage.setItem("companyData", JSON.stringify(responseData));
          this.productsService.companyData = responseData;
          this.productsService.companyName = responseData["company_name"];
          setTimeout(() => {}, 700);
        }
      });
  }
}
